<template>
  <div>

    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-component _2 w-nav">
      <div class="navbar-container w-container">
        <a id="w-node-_2bc7849c-fc53-2a49-059f-aa960ee8e78d-0ee8e78b" @click="goToHome" class="navbar-logo-link no-rizer w-nav-brand"><img src="../../../../../public/images/Logo.svg" loading="lazy" alt="" class="navbar-logo"></a>
      </div>
    </div>
    <div class="section color mid-line">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form one w-container">
        <div class="form-regidet w-form">
          <div class="wrap-steps">
            <div class="step">
              <div class="step-number active">
                <div class="number-txt">1</div>
                <div data-w-id="d73203a7-f081-4301-5c74-acb811806f80" class="pulse"></div>
              </div>
            </div>
            <div class="step">
              <div class="step-number">
                <div>2</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number">
                <div>3</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number">
                <div>4</div>
              </div>
            </div>
          </div>
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet" data-wf-page-id="652921c910bae02d8870dddd" data-wf-element-id="d73203a7-f081-4301-5c74-acb811806f90">
            <v-form ref="form_reg1" v-model="valid_reg1" lazy-validation>
              <div class="line-progress"></div>
              <div class="tittle-form">
                <h1 class="heading-form">Общие сведения</h1>
                <div class="text-classic-link">Уже есть аккант? <a @click="goToLogin" class="link-form">Вход</a>
                </div>
              </div>
              <div class="w-layout-vflex flex-form" style="margin-top: 15px;">
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Last-Name-2" class="input-label">Фамилия</label>
                    <v-text-field
                        id="Last-Name-2"
                        name="Last-Name-2"
                        outlined
                        type="text"
                        inputmode="text"
                        :rules="nameRules"
                        v-model="registration_data.profile.surname"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="First-Nam" class="input-label">Имя</label>
                    <v-text-field
                        id="First-Name"
                        name="First-Name"
                        outlined
                        inputmode="text"
                        type="text"
                        :rules="nameRules"
                        v-model="registration_data.profile.name"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Last-Last-Name" class="input-label">Отчество</label>
                    <v-text-field
                        id="Last-Last-Name"
                        name="Last-Last-Name"
                        outlined
                        inputmode="text"
                        type="text"
                        :rules="middleNameRules"
                        v-model="registration_data.profile.middle_name"
                    ></v-text-field>
                  </div>

                  <label class="w-checkbox checkbox-wrap-next">
                    <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-checkbox-2"
                         :class="{ 'w--redirected-checked': dontHaveMiddleName }"
                         @click.prevent="toggleCheckbox">
                    </div>
                    <input
                        v-model="dontHaveMiddleName"
                        type="checkbox"
                        style="opacity:0;position:absolute;z-index:-1">
                    <span class="text-classic top w-form-label" for="Last-Last-Name">Без отчества</span>
                  </label>
                </div>

                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Phone" class="input-label">Телефон</label>
                    <v-text-field
                        id="Phone"
                        name="Phone"
                        outlined
                        inputmode="numeric"
                        type="tel"
                        v-mask="'+7##########'"
                        :rules="phoneRules"
                        v-model="registration_data.profile.phone"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="Email" class="input-label">Email</label>
                    <v-text-field
                        id="Email"
                        name="Email"
                        outlined
                        inputmode="email"
                        type="email"
                        :rules="emailRules"
                        v-model="registration_data.profile.email"
                    ></v-text-field>
                  </div>
                </div>

                <label class="w-checkbox checkbox-wrap-next">
                  <div
                      class="w-checkbox-input w-checkbox-input--inputType-custom ms-checkbox-2"
                      :class="{ 'w--redirected-checked': agreement_all }"
                      @click.prevent="toggleCheckboxAgreement">
                  </div>
                  <input
                      v-model="agreement_all"
                      type="checkbox"
                      name="Last-Last-Name-3"
                      id="Last-Last-Name-3"
                      data-name="Last Last Name 3"
                      style="opacity:0;position:absolute;z-index:-1">
                  <span
                      @click.prevent="toggleCheckboxAgreement"
                      class="text-classic top bold w-form-label"
                      for="Last-Last-Name-3">Я соглашаюсь со всеми ниже указанными пунктами:</span>
                </label>

                <label class="w-checkbox checkbox-wrap-next">
                  <div
                      class="w-checkbox-input w-checkbox-input--inputType-custom ms-checkbox-2"
                      :class="{ 'w--redirected-checked': agreement_1 }"
                      @click.prevent="agreement_1 = !agreement_1">
                  </div>
                  <input
                      v-model="agreement_1"
                      type="checkbox"
                      name="Soglachenie"
                      id="Soglachenie"
                      data-name="Soglachenie"
                      style="opacity:0;position:absolute;z-index:-1">
                  <span
                      @click.prevent="agreement_1 = !agreement_1"
                      class="text-classic top w-form-label"
                      for="Soglachenie">Я ознакомлен и принимаю <a @click="goToDocument('signature-analog')" class="link-form">Соглашение об использовании аналога собственноручной подписи</a></span>
                </label>

                <label class="w-checkbox checkbox-wrap-next">
                  <div
                      class="w-checkbox-input w-checkbox-input--inputType-custom ms-checkbox-2"
                      :class="{ 'w--redirected-checked': agreement_2 }"
                      @click.prevent="agreement_2 = !agreement_2">
                  </div>
                  <input
                      v-model="agreement_2"
                      type="checkbox"
                      name="Soglachenie-2"
                      id="Soglachenie-2"
                      data-name="Soglachenie 2"
                      style="opacity:0;position:absolute;z-index:-1">
                  <span
                      @click.prevent="agreement_2 = !agreement_2"
                      class="text-classic top w-form-label"
                      for="Soglachenie-2">Я разрешаю уступку кредитором третьим лицам прав (требований) по договору потребительского займа</span>
                </label>

                <label class="w-checkbox checkbox-wrap-next">
                  <div
                      class="w-checkbox-input w-checkbox-input--inputType-custom ms-checkbox-2"
                      :class="{ 'w--redirected-checked': agreement_3 }"
                      @click.prevent="agreement_3 = !agreement_3">
                  </div>
                  <input
                      v-model="agreement_3"
                      type="checkbox"
                      name="Soglachenie-2"
                      id="Soglachenie-2"
                      data-name="Soglachenie 2"
                      style="opacity:0;position:absolute;z-index:-1">
                  <span
                      @click.prevent="agreement_3 = !agreement_3"
                      class="text-classic top w-form-label"
                      for="Soglachenie-2">Я ознакомлен с <a @click="goToDocument('loan-rules')" class="link-form">Правилами предоставления микрозаймов</a></span>
                </label>

                <label class="w-checkbox checkbox-wrap-next">
                  <div
                      class="w-checkbox-input w-checkbox-input--inputType-custom ms-checkbox-2"
                      :class="{ 'w--redirected-checked': agreement_4 }"
                      @click.prevent="agreement_4 = !agreement_4">
                  </div>
                  <input
                      v-model="agreement_4"
                      type="checkbox"
                      name="Soglachenie-2"
                      id="Soglachenie-2"
                      data-name="Soglachenie 2"
                      style="opacity:0;position:absolute;z-index:-1">
                  <span
                      @click.prevent="agreement_4 = !agreement_4"
                      class="text-classic top w-form-label"
                      for="Soglachenie-2">Я ознакомлен с <a @click="goToDocument('general-terms-of-agreement')" class="link-form">Общими условиями договора потребительского займа</a></span>
                </label>

                <label class="w-checkbox checkbox-wrap-next">
                  <div
                      class="w-checkbox-input w-checkbox-input--inputType-custom ms-checkbox-2"
                      :class="{ 'w--redirected-checked': agreement_5 }"
                      @click.prevent="agreement_5 = !agreement_5">
                  </div>
                  <input
                      v-model="agreement_5"
                      type="checkbox"
                      name="Soglachenie-2"
                      id="Soglachenie-2"
                      data-name="Soglachenie 2"
                      style="opacity:0;position:absolute;z-index:-1">
                  <span
                      @click.prevent="agreement_5 = !agreement_5"
                      class="text-classic top w-form-label"
                      for="Soglachenie-2">Я подтверждаю, что в отношениях с ООО МКК «Триллион заем» действую сознательно, свободно, своей волей и в своих интересах, мои действия не контролируются иными третьими лицами, самостоятельно представляю свои интересы!</span>
                </label>

                <label class="w-checkbox checkbox-wrap-next">
                  <div
                      class="w-checkbox-input w-checkbox-input--inputType-custom ms-checkbox-2"
                      :class="{ 'w--redirected-checked': agreement_6 }"
                      @click.prevent="agreement_6 = !agreement_6">
                  </div>
                  <input
                      v-model="agreement_6"
                      type="checkbox"
                      name="Soglachenie-2"
                      id="Soglachenie-2"
                      data-name="Soglachenie 2"
                      style="opacity:0;position:absolute;z-index:-1">
                  <span
                      @click.prevent="agreement_6 = !agreement_6"
                      class="text-classic top w-form-label"
                      for="Soglachenie-2">Я ознакомлен с <a @click="goToDocument('privacy-policy')" class="link-form">Политикой в отношении обработки персональных данных</a> и даю <a @click="goToDocument('processing-personal-data')" class="link-form">Согласие</a> на обработку персональных данных</span>
                </label>
              </div>

              <a
                  id="openLabel"
                  @click="sendPhone"
                  style="margin-top: 15px;"
                  aria-current="page"
                  class="button-bust w-button w--current">
                Продолжить
              </a>
              <link rel="prerender" @click="sendPhone">
            </v-form>
          </form>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'RegistrationStep1',
  data() {
    return {
      valid_reg1: false,

      nameRules: [
        v => this.dontHaveMiddleName || !!v || 'Поле не может быть пустым',
        v => (v && v.length <= 256) || 'Максимальная длина 256 символов'
      ],
      emailRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Неправильный формат email'
      ],
      phoneRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^(8\d{10}|\+7\d{10})$/.test(v) || 'Телефон должен состоять из 11 цифр и начинаться с 8 или +7'
      ],

      dontHaveMiddleName: false,

      agreement_all: false,
      agreement_1: false,
      agreement_2: false,
      agreement_3: false,
      agreement_4: false,
      agreement_5: false,
      agreement_6: false
    };
  },
  components: {
    Footer
  },
  computed: {
    middleNameRules() {
      return [
        v => this.validateMiddleName(v) || 'Поле не может быть пустым',
        v => !v || v.length <= 256 || 'Максимальная длина 256 символов'
      ];
    },
    registration_data: {
      get() {
        return this.$store.getters.REGISTRATION_DATA;
      },
      set(value) {
        this.$store.commit('SET_REGISTRATION_DATA', value);
      }
    }
  },
  created() {
  },
  methods: {
    validateMiddleName(value) {
      // Если галочка установлена, позволяем оставить поле пустым
      if (this.dontHaveMiddleName) {
        return true;
      }
      // В противном случае, проверяем что поле не пустое
      return !!value;
    },
    goToDocument(name_doc) {
      this.$router.push('/documents/' + name_doc);
    },
    toggleCheckbox() {
      this.dontHaveMiddleName = !this.dontHaveMiddleName;
    },
    toggleCheckboxAgreement() {
      this.agreement_all = !this.agreement_all;
      this.agreement_1 = this.agreement_all;
      this.agreement_2 = this.agreement_all;
      this.agreement_3 = this.agreement_all;
      this.agreement_4 = this.agreement_all;
      this.agreement_5 = this.agreement_all;
      this.agreement_6 = this.agreement_all;
    },
    goToLogin() {
      this.$router.push('/login');
    },
    goToHome() {
      this.$router.push('/');
    },
    sendPhone() {
      if (this.$refs.form_reg1.validate()) {
        if (
            this.agreement_1 &&
            this.agreement_2 &&
            this.agreement_3 &&
            this.agreement_4 &&
            this.agreement_5 &&
            this.agreement_6
        ){
          this.$router.push('/registration-step1-phone');
        } else {
          this.$buefy.toast.open({
            message: 'Примите соглашения',
            type: 'is-danger'
          });
        }
      } else {
        this.$buefy.toast.open({
          message: 'Заполните корректно поля!',
          type: 'is-danger'
        });
      }
    },
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
